<template>
	<template v-if="browserSupported">
		<LoadingBar />
		<TheHeader />

		<router-view v-slot="{ Component }">
			<component :is="Component" />
		</router-view>

		<FloatingContact />
		<FloatingMiniGames v-if="isFeatureEnabled['MINIGAME']" />

		<TheFooter v-if="route.path == '/' || !isMobile" />
		<MobileDock v-if="isMobile" />

		<ModalGroup />
		<AnnouncementView />
		<TheSignalr />
	</template>
	<template v-else>
		<PlatformSupported :supportedBrowserList="supportedBrowserList" />
	</template>
</template>

<script setup>
import "@/composables/useDarkTheme";
import "@/composables/useCheckConnection";
import PlatformSupported from "./components/layout/PlatformSupported.vue";
import ModalGroup from "@/components/layout/ModalGroup.vue";
import TheHeader from "@/components/layout/header/TheHeader.vue";
import TheFooter from "@/components/layout/TheFooter.vue";
import BaseFooter from "@/components/layout/BaseFooter.vue";
import TheSignalr from "./components/layout/TheSignalr.vue";
import AnnouncementView from "./components/layout/AnnouncementView.vue";
import LoadingBar from "./components/layout/LoadingBar.vue";
import MobileDock from "./components/layout/MobileDock.vue";
import FloatingContact from "@/components/layout/FloatingContact.vue";
import FloatingMiniGames from "@/components/layout/FloatingMiniGames.vue";
import UAParser from "ua-parser-js";

import { ref, computed, watch, onMounted, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import { isLogin, watchLogout } from "@/composables/useAuth";
import { useIsMobile } from "@/composables/useMobile";
import { detectIOSNavGesture } from "@/composables/usePlatform";
import { languageCodeToId } from "@/composables/useCulture";
import { fetchBeforeLoginList, fetchAfterLoginList } from "@/composables/useInitAPI";
import { isFeatureEnabled } from "@/constant";

const { t, locale } = useI18n();
const store = useStore();
const router = useRouter();
const route = useRoute();
const isMobile = useIsMobile();
const toast = useToast();

// ================= Supported Browser Start =================

const parser = new UAParser();
const parsedUa = parser.getResult();
const deviceInfos = {
	os: parsedUa.os.name,
	browser: parsedUa.browser.name,
};

const supportedBrowserList = [
	{ name: "safari", showUI: true },
	{ name: "chrome", showUI: true },
	{ name: "edge", showUI: true },
	{ name: "firefox", showUI: true },
	{ name: "facebook", showUI: false },
	// { name: "wechat", showUI: false },
];
const browserSupported = supportedBrowserList.find((browser) =>
	deviceInfos.browser?.toLowerCase().includes(browser.name)
);

// ================= Supported Browser End =================

// ================= Add To Home Screen Start =================

const addToHomeScreenBanner = ref(null);
const addToHomescreen = async () => {
	addToHomeScreenBanner.value?.addToHomescreen(false);
};

// ================= Add To Home Screen End =================

// ================= Login Start =================

// fetch only when user is logged in
watch(isLogin, async (newLoginStatus) => {
	if (newLoginStatus) {
		fetchAfterLoginList();
	}
});

// ================= Login End =================

// ================= Router Start =================

const redirectHomePage = () => {
	store.dispatch("identityServer/logOut");
	router.push("/");
};

// ================= Router End =================

// ================= Modal Start =================

const displayModal = computed(() => {
	return store.state.showModal;
});
const closeModal = () => {
	store.commit("showModal", { status: false });
};

// ================= Modal End =================

// ================= Notifications Start =================

const getUnreadInboxCount = computed(() => {
	return store.getters["inbox/getInboxUnreadCount"];
});

watch(getUnreadInboxCount, (newValue) => {
	if (window.Notification) {
		if ("setAppBadge" in navigator) {
			if (newValue > 0) {
				navigator.setAppBadge(newValue);
			} else {
				navigator.clearAppBadge();
			}
		} else {
			if (window.Notification.permission == "default") {
				Notification.requestPermission();
			}
		}
	}
});

// ================= Notifications End =================

// Fetch regardless of login status
watch(route, (newRoute) => {
	if (newRoute.meta?.refresh?.value) {
		fetchBeforeLoginList();
	}
});

onMounted(() => {
	detectIOSNavGesture();
	if (window.Notification) {
		if (window.Notification.permission == "default") {
			Notification.requestPermission();
		}
	}

	// bonus hunter prevention
	watchLogout();
});
</script>
