<template>
	<nav>
		<div class="navList container">
			<div class="navListLeft">
				<!-- HOME -->
				<UnifiedLink :isRouteLink="home.isRouteLink" :link="home.link">
					<div class="navItem">
						<font-awesome-icon class="navIcon homeIcon" :icon="home.icon" />
					</div>
				</UnifiedLink>

				<!-- DOWNLOAD -->
				<UnifiedLink :isRouteLink="download.isRouteLink" :link="download.link">
					<div class="navItem">
						<font-awesome-icon class="navIcon downloadIcon" :icon="download.icon" />
						<span class="navText">{{ t(`route.${download.title}`) }}</span>
					</div>
				</UnifiedLink>

				<!-- SLOTS -->
				<template v-for="categoryItem in filteredCategoryList" :key="categoryItem">
					<CustomDropdown
						:toggleByHover="true"
						:floatRight="true"
						@open="categoryItem.isOpen = true"
						@close="categoryItem.isOpen = false"
						ref="navDropdown"
						:itemData="categoryItem"
					>
						<template #toggle>
							<UnifiedLink
								:isRouteLink="categoryItem.nav.isRouteLink"
								:link="categoryItem.nav.link"
								@click="hideDropdown(categoryItem)"
							>
								<div class="navItem" tabindex="0">
									<span class="navText">{{ t(`route.${categoryItem.nav.title}`) }}</span>
								</div>
							</UnifiedLink>
						</template>

						<template #dropdown>
							<div class="navGameContainer container">
								<VendorListView
									v-if="categoryItem.vendorList.length && categoryItem.category"
									:vendorList="categoryItem.vendorList"
									:category="categoryItem.category"
									:assetPath="'Vava/'"
									@clickGame="hideDropdown(categoryItem)"
								/>
							</div>
						</template>
					</CustomDropdown>
				</template>

				<!-- PROMOTION -->
				<UnifiedLink :isRouteLink="promotion.isRouteLink" :link="promotion.link">
					<div class="navItem">
						<font-awesome-icon class="navIcon promotionIcon" :icon="promotion.icon" />
						<span class="navText">{{ t(`route.${promotion.title}`) }}</span>
					</div>
				</UnifiedLink>

				<!-- VIP -->
				<UnifiedLink :isRouteLink="vip.isRouteLink" :link="vip.link" :validateLogin="vip.validateLogin">
					<div class="navItem">
						<font-awesome-icon class="navIcon vipIcon" :icon="vip.icon" />
						<span class="navText">{{ t(`route.${vip.title}`) }}</span>
					</div>
				</UnifiedLink>

				<!-- REFERRAL -->
				<UnifiedLink
					v-if="isFeatureEnabled['REFERRAL']"
					:isRouteLink="referral.isRouteLink"
					:link="referral.link"
					:validateLogin="referral.validateLogin"
				>
					<div class="navItem">
						<font-awesome-icon class="navIcon referralIcon" :icon="referral.icon" />
						<span class="navText">{{ t(`route.${referral.title}`) }}</span>
					</div>
				</UnifiedLink>
			</div>
		</div>
	</nav>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { isFeatureEnabled, routeData } from "@/constant";
import { useI18n } from "vue-i18n";
import { isLogin } from "@/composables/useAuth";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import UnifiedLink from "@/components/dynamic/UnifiedLink.vue";
import CustomDropdown from "@/components/dynamic/CustomDropdown.vue";
import CustomDropdownItem from "@/components/dynamic/CustomDropdownItem.vue";
import BaseImage from "@/components/dynamic/BaseImage.vue";
import VendorListView from "@/components/content/VendorListView.vue";
import { GameType } from "@/common/GameType";
import { useLoading } from "vue-loading-overlay";
import { useIsMobile } from "@/composables/useMobile";
import { useToast } from "vue-toastification";
import { toastOptionSuccess, toastOptionError, toastOptionWarning } from "@/composables/useToastOptions";

const { t, locale } = useI18n();
const imgUrlDomain = process.env.VUE_APP_Media_Domain;
const store = useStore();
const $loading = useLoading();
const isMobile = useIsMobile();
const router = useRouter();
const route = useRoute();
const toast = useToast();

// ================= Nav Start =================

const vendorImgPath = "VendorImage/Thumbnail/";
const isGamesDropdownOpen = ref(false);

// Common nav
const home = routeData.find((item) => item.title == "home");
const download = routeData.find((item) => item.title == "download");
const promotion = routeData.find((item) => item.title == "promotion");
const vip = routeData.find((item) => item.title == "vip");
const referral = routeData.find((item) => item.title == "referral");

// Games nav
const isSlotsDropdownOpen = ref(false);
const slots = routeData.find((item) => item.title == "slots");
const casino = routeData.find((item) => item.title == "casino");
const fishing = routeData.find((item) => item.title == "fishing");
const sports = routeData.find((item) => item.title == "sports");

const navDropdown = ref(null);

const hideDropdown = () => {
	navDropdown.value.map((navCategory) => navCategory.toggleDropdown("close"));
};

// ================= Category Start =================

const navCategoryList = ref([
	{
		shortCode: "SL",
		navTitle: "slots",
		categoryImgPath: "Slots/",
		isOpen: false,
		isActive: false,
		vendorList: [],
		nav: null,
		category: null,
	},
	{
		shortCode: "LC",
		navTitle: "casino",
		categoryImgPath: "Casino/",
		isOpen: false,
		isActive: false,
		vendorList: [],
		nav: null,
		category: null,
	},
	{
		shortCode: "FS",
		navTitle: "fishing",
		categoryImgPath: "Fishing/",
		isOpen: false,
		isActive: false,
		vendorList: [],
		nav: null,
		category: null,
	},
	{
		shortCode: "SP",
		navTitle: "sports",
		categoryImgPath: "Sports/",
		isOpen: false,
		isActive: false,
		vendorList: [],
		nav: null,
		category: null,
	},
]);

const getVendorCategoryList = computed(() => {
	return store.getters["vendors/getCategoryList"];
});

const filteredCategoryList = computed(() => {
	return navCategoryList.value.map((item) => {
		return {
			...item,

			nav: routeData.find((route) => route.title == item.navTitle),
			isActive: getVendorCategoryList.value.find((x) => x.shortCode == item.shortCode)?.status,
			category: getVendorCategoryList.value.find((category) =>
				category?.name?.replaceAll(" ", "")?.toLowerCase().includes(item.navTitle)
			),

			vendorList: vendorList.value
				.filter((vendor) =>
					vendor.categories.find((category) => GameType[category.name.replace(" ", "_")] == item.shortCode)
				)
				.toSorted((a, b) => {
					const sequenceA = a.categories.find((category) =>
						category?.name?.toLowerCase().replaceAll(" ", "").includes(item.navTitle)
					)?.sequence;
					const sequenceB = b.categories.find((category) =>
						category?.name?.toLowerCase().replaceAll(" ", "").includes(item.navTitle)
					)?.sequence;
					return sequenceA - sequenceB;
				}),
		};
	});
});

const vendorList = computed(() => store.getters["vendors/getVendor"]);
const hotVendorList = [];

// ================= Category End =================

// ================= Game Start =================

// ================= Game End =================

// ================= Nav End =================
</script>

<style scoped lang="sass">
nav
	background: var(--gradient-secondary)
.navList
	display: flex
	justify-content: center
	align-items: center
	flex-wrap: wrap
.navListLeft, .navListRight
	display: flex
	align-items: center
.navItem
	position: relative
	display: flex
	align-items: center
	gap: 0.5rem
	font-size: 1.05em
	font-weight: var(--font-medium)
	line-height: 1.1rem
	text-wrap: nowrap
	padding: 0.8rem 1.4rem
	overflow: hidden
	text-transform: uppercase
	color: var(--text-inverted)
	&::after
		position: absolute
		content: ""
		width: 100%
		height: 4px
		background: var(--text-accent)
		bottom: 0
		left: 50%
		transform: translate(-50%, 100%)
		// border-radius: 4px 4px 0 0
		transition: 0.2s
	&:hover
		color: var(--text-accent)
		&::after
			transform: translate(-50%)
		.downloadIcon
			animation-name: dropFromTop
			animation-duration: 0.25s
			animation-timing-function: ease-in-out
		.promotionIcon
			animation-name: jump
			animation-duration: 0.35s
			animation-timing-function: linear
		.vipIcon
			animation-name: spinY
			animation-duration: 0.5s
			animation-timing-function: ease-in-out
		.referralIcon
			animation-name: danceBottom
			animation-duration: 0.5s
			animation-timing-function: ease-in-out
.navText
	line-height: normal
.navIcon
	&.downloadIcon
		font-size: 1.15em
.router-link-active .navItem
	color: var(--text-accent)
	&::after
		transform: translate(-50%)
.homeIcon
	font-size: 1.1rem

:deep(.dropdownContainer)
	position: unset
:deep(.dropdownContainer .dropdownPanel)
	min-width: 100%
	left: 0
	border-radius: 0
	background: var(--dropdown-background-translucent)
	padding-block: 0
:deep(.dropdown-enter-active), :deep(.dropdown-leave-active)
	pointer-events: none
	// let users move their mouse through navbar easily without getting blocked by the dropdown

// .navGameContainer
// 	max-height: 75vh
:deep(.vendorListView)
	margin-block: 0
	padding-block: 1rem
:deep(.vendorItem)
	gap: 0
	transition: 0.1s
	&:not(.disabled):hover
		transform: scale(1.05)
:deep(.vendorList)
	grid-template-columns: repeat(auto-fill, 9rem)
:deep(.vendorImage)
	aspect-ratio: 1/1
</style>
