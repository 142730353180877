import httpClient, { ClientId } from "./HttpClient";
import { LiveTransactionResponse, ProgressiveJackpotResponse } from "@/types/LiveData/Response/liveDataResponse";

const fetchProgressiveJackpot = (): Promise<ProgressiveJackpotResponse> => {
	const endPoint = `/api/Jackpot/Detail`;
	const params = {
		VendorId: null,
		ClientId: ClientId,
	};
	const data = httpClient.get(endPoint, { params }).then((response) => response.data);
	return data;
};

const fetchLiveTransactions = (transactionType = "Deposit"): Promise<LiveTransactionResponse[]> => {
	const endPoint = `/api/Payment/GetTransactionLastRecordInfo`;
	const params = {
		ClientId: ClientId,
		TransactionType: transactionType,
		TakeCount: null,
	};
	const data = httpClient.get(endPoint, { params }).then((response) => response.data);
	return data;
};

export { fetchLiveTransactions, fetchProgressiveJackpot };
