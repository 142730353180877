import { ProgressiveJackpotResponse, LiveTransactionResponse } from "@/types/LiveData/Response/liveDataResponse";

export interface LiveDataStateInterface {
	progressiveJackpot: ProgressiveJackpotResponse;
	liveDepositList: LiveTransactionResponse[];
	liveWithdrawalList: LiveTransactionResponse[];
}

function state(): LiveDataStateInterface {
	return {
		progressiveJackpot: {} as ProgressiveJackpotResponse,
		liveDepositList: [] as LiveTransactionResponse[],
		liveWithdrawalList: [] as LiveTransactionResponse[],
	};
}

export default state;
