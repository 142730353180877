import terms from "@/resource/terms/terms.zh";
import privacyPolicy from "@/resource/privacyPolicy/privacyPolicy.zh";
import responsible from "@/resource/responsibleGaming/responsible.zh";
import goPlusTerms from "@/resource/goPlusTerms/goPlusTerms.zh";

const brandName = "BBKing88";
const year = "2024";
const month = "9";

export default {
	common: {
		brandName: `${brandName}`,
		year: `${year}`,
		pageNotFound: "找不到该页面",
		userId: "用户ID",
		dob: "出生日期",
		games: "游戏",
		close: "关闭",
		doNotShowAgain: "今日不再显示",
		readAll: "查看全部",
		announcement: "公告",
		live: "直播",
		download: "下载",
		refresh: "刷新",
		user: "用户",
		username: "账号",
		fullName: "全名",
		asFullName: "与您的银行帐户名一致",
		password: "密码",
		confirmPassword: "确认密码",
		currentPassword: "当前密码",
		newPassword: "新密码",
		copy: "复制",
		copySuccess: "复制成功",
		copyFailed: "复制失败",
		search: "搜索",
		numberOfResults: "{number}个搜索结果",
		itemsPerPage: "每页显示的结果",
		submit: "提交",
		update: "更新",
		pleaseLogin1: "请",
		pleaseLogin2: "以获取您的用户名和密码。",
		yes: "是",
		no: "不",
		requestTac: "请求TAC",
		verificationCode: "验证码",
		resendInSeconds: "{time}秒后可重发",
		phoneNumber: "手机号码",
		EnterYourPhoneNumber: "请输入您的电话号码",
		EnterYourPassword: "请输入您的密码",
		EnterYourFullName: "请输入您的全名",
		RetypeYourPassword: "请重新输入您的密码",
		referralCode: "推荐码",
		optional: "可选",
		termsConsent: {
			iAmOver18: "我已年满18岁并且我接受这些",
			and: "和",
			iHaveReadAndAgreedToThe: "我已阅读并同意",
		},
		nextStep: "下一步",
		next: "下一步",
		nextLevel: "下个级别",
		current: "当前级别",
		accountSetup: "账户设置",
		tac: "TAC",
		done: "完成",
		back: "返回",
		resendTac: "重发TAC",
		success: "成功",
		registerSuccess: "您的帐户已创建成功！",
		on: "开",
		off: "关",
		min: "最低",
		max: "最高",
		notice: "重要提醒",
		in: "进",
		out: "出",
		startDate: "开始日期",
		endDate: "结束日期",
		noData: "无数据",
		provider: "游戏平台",
		selectProvider: "选择游戏平台",
		facebook: "Facebook",
		whatsApp: "WhatsApp",
		telegram: "Telegram",
		email: "邮件",
		line: "LINE",
		twitter: "Twitter",
		claim: "领取",
		claimNow: "立即领取",
		areYouSure: "您是否确认？",
		cannotChangeAgain: "此后您将无法再次更改。",
		youAreGoingToClaim: "您将领取这笔奖金。",
		welcomeToBrandName: `欢迎来到 ${brandName}`,
		loginToContinue: `登录以继续体验 ${brandName} 应用程序`,
		ResetPassword: "重置密码",
		ResetPasswordDesc: "请输入您的电话号码以重置密码",
		EnterNewPassword: "请输入您的新密码",
		more: "更多",
		less: "更少",
		selectDate: "选择日期",
		selectCategory: "选择类别",
		today: "今天",
		last24Hours: "过去24小时",
		lastNDays: "过去{days}天",
		lastUpdateOn: "最近更新于 {updateTime}",
		light: "浅色",
		dark: "深色",
		system: "系统",
		note: "注意",
		bankInfoNote: "您的帐户中只能绑定一个银行户口。",
		scanToObtainReferralCode: "请扫描二维码以前往注册",
		welcomeMember: "欢迎, {membername}",
		registerWelcome: "要成为会员，只需扫描现有会员提供的QR码。扫描后，请按照提示完成注册。 欢迎加入！",
		shareLinkText: `跟我一起加入 ${brandName} 并获得现金奖励！`,
		cancel: "取消",
		edit: "编辑",
		checkIn: "签到",
		total: "总额",
		viewReferrals: "查看推荐",
		viewReceipt: "查看收据",
		balance: "余额",
		totalBalance: "总余额",
		totalEarnings: "总收益",
		principalAmount: "本金",
		earnedInterest: "赚得利息",
		nettDailyReturnRate: "每日净收益率",
		nettWeeklyReturnRate: "每周净收益率",
		cashIn: "转入",
		cashOut: "转出",
		totalCashOut: "转出总额",
		recentTransactions: "近期交易",
		recentPayouts: "近期支出",
		viewAll: "查看全部",
		viewLess: "减少",
		goPlusWarning: "您的余额已接近最高限额：{max}。 一旦达到限额，您的存款将停止增长。",
		goPlusLimit: "您的余额已达到最大限额: {max}。 请提款以继续获得收入。",
		pullDownToRefresh: "下拉刷新",
		releaseToRefresh: "松开即可刷新",
		releaseToFullRefresh: "松开即可全面刷新",
		onLoading: "加载中...",
		copyright: `保留所有版权 © ${year} ${brandName}`,
		termsAndConditions: "条款与协议",
		FAQ: "常见问题",
		day: "天",
		week: "周",
		dailyPayout: "每日收益",
		weeklyPayout: "每周收益",
		onlyRecordsUpToNDays: "仅提供最多{days}天的记录",
		cashInVia: "通过以下方式转入",
		cashOutTo: "转出至",
		enterAmount: "输入金额",
		enterAmountMin: "输入金额 （最低 {min}）",
		maximum: "最大",
		minimum: "最小",
		transferableBalance: "可转余额",
		totalAmount: "总金额",
		allAmountWillBeCashedOut: `您的${brandName}+钱包将全额转出转出。`,
		question: "问",
		answer: "答",
		addToHomeScreen: "添加到主屏幕",
		addToHomeScreenInfo: "添加到主屏幕并享受身临其境的体验！",
		install: "安装",
		referralNow: "立即推荐!",
		interestInsufficientBalance: "您需要至少{min}的可转账余额才能转入。",
		noBalanceToBeCashedOut: "没有余额可转出。",
		comingSoon: "即将推出",
		underMaintenance: "正在维修",
		confirmDeposit: "确认存款",
		depositToLockedWallet: "您的存款金额{currency}{amount}将存入您的锁定钱包[{category}]。",
		yourNewTurnover: "新的流水额将为{amount}。",
		doYouWishToProceed: "您是否想继续？",
		topup: "充值",
		loginRegister: "登入/注册",
		confirm: "确定",
		rank: "等级",
		hot: "热门",
		"turnover exist": "流水额存在",
		"winover exist": "总赢额存在",
		transferSummary: "转账报告",
		transferAndPlay: "转账并开始游戏",
		howDoesThisWork: "如何操作？",
		details: "详情",
		memberService: "会员服务",
		restricted: "受限",
		ticketsAvailable: "可用票数",
		redirecting: "跳转中",
		youAreBeingPaymentGateway: "请稍等，您现在正被转到支付平台...",
		unsupportedBrowser: "浏览器不受支持",
		pleaseSwitchBrowser: `为了获得最佳${brandName}体验，请切换到受支持的浏览器`,
		pleaseSelect: "--- 请选择 ---",
		areYouNewHere: "您是新用户吗？",
		alreadyAMember: "已经是会员？",
		maxPayout: "最高奖金限额: {maxPayout}",
		progressiveJackpot: "累积奖金",
		liveTransactions: "实时交易",
	},
	scan: {
		ScanBarCodeOrQRCode: "扫描条形码或二维码",
		ScanFromGallery: "从图库中扫描",
	},
	games: {
		viewMoreGames: "查看更多游戏",
		speedBacarrat: "速看百家乐",
		betNow: "立即下注",
		playNow: "立即游玩",
		play: "立即游玩",
		username: "账号",
		password: "密码",
		instructions: "使用指南",
		instructionsDetails: "请使用提供的账号和密码登录",
		phoneNumber: "Phone Number",
		referralCode: "Referral Code",
	},
	account: {
		mainWallet: "主钱包",
		lockedWallet: "锁定钱包",
		depositNow: "立即存款",
		transferAmount: "转账金额",
		promoCode: "优惠码",
		promotion: "优惠",
		selectPromotion: "选择优惠",
		bankDetails: "银行资料",
		bankName: "银行名",
		accountHolderName: "账户持有人姓名",
		accountName: "账户名",
		accountNumber: "账户号码",
		selectBank: "选择银行",
		viewFullWallet: "查看钱包",
		general: "通用",
		payment: "资金",
		banking: "资金",
		account: "账户",
		autoTransfer: "主钱包自动转账",
		paymentMethod: "付款方式",
		selectPaymentMethod: "选择付款方式",
		depositAmount: "存款金额",
		deposit: "存款",
		depositNotice: [
			{
				notice: "在存款之前，请务必查看我们当前最新的存款账户资料。",
			},
			{
				notice: "存款账户名称必须与注册全名相同。",
			},
			{
				notice: "不允许使用别人的银行帐户，支票，银行汇票和信用卡进行存款。",
			},
			{
				notice: "使用“网上转帐”选项的用户，请先确保存款交易成功后再提交存款申请，以避免存款延迟。",
			},
		],
		withdrawalNotice: [
			{
				notice: "提款银行户口名字必须与注册全名相同。会员将无法提款到第三方银行帐户。",
			},
			{
				notice: "在提交提款之前，请确保银行户口名字和银行户口号码正确无误。",
			},
			{
				notice: "一旦您成功提交了提款申请表，并且在我们的帐户中结清了您的资金，我们的团队会尽快为您处理。如果处理时间超过10分钟，请与我们的客户服务联系。 他们将24/7随时为您服务。",
			},
		],
		depositChannel: "存款管道",
		selectChannel: "选择管道",
		onlineTransfer: "在线转账",
		cashDepositMachine: "现金存款机",
		atm: "自动取款机",
		overTheCounter: "柜台",
		cheque: "支票",
		uploadReceipt: "上传收据",
		minimumDeposit: "最低存款额",
		minimumWithdrawal: "最低提款额",
		minWithdrawal: "最低提款额",
		maxWithdrawal: "最高提款额",
		dailyLimit: "每日额度",
		withdrawalAmount: "提款金额",
		availableBalance: "可用余额",
		chipsBalance: "筹码余额",
		transactionHistory: "交易纪录",
		transferFrom: "转自",
		transferTo: "转至",
		selectWallet: "选择钱包",
		mainWalletBalance: "主钱包余额",
		restore: "转回",
		winover: "总赢额",
		winovererBalance: "总赢余额",
		turnover: "流水额",
		turnoverBalance: "流水余额",
		referNow: "立即推荐",
		referralRewards: "推荐奖励",
		youHave: "您有",
		connections: "个下线",
		totalCashEarned: "所赚取现金总额",
		successfulReferrals: "推荐成功",
		yourReferrals: "您的推荐名单",
		referralStates: {
			registered: "完成注册",
			depositSuccessful: "存款{amount}成功",
			claim: "领取",
			claimed: "已领取",
		},
		unclaimed: "未领取",
		inProgress: "进行中",
		claimAll: "一键领取",
		allClaimed: "已领取全部",
		bonusOngoing: "优惠进行中",
		requirementNotMet: "未满足要求",
		referralCode: "推荐代码",
		referralLink: "推荐链接",
		successfulRegistrations: "成功注册",
		shareToFriends: "分享给您的好友",
		referralTerms: "条款和条件适用于所有的推荐金。",
		referralInstructions: "将此二维码展示给朋友，扫描并完成注册即可获得推荐奖金。.",
		minDepositRequired: "您需要至少存款 {minDeposit} 次才能参加此推荐计划。",
		birthdayBonus: "生日奖金",
		birthdayConditionYearlyClaim: "每位会员每年仅限申请此奖金一次。",
		birthdayConditionFirstDeposit: "此奖金只限定于已经充值的会员。",
		birthdayConditionBirthdayMonth: "会员只可以在生日的前后15天领取生日奖金。",
		birthdayConditionMemberDuration: `会员需要加入${brandName}至少一个月。`,
		birthdayExample: "例： 会员的生日是在5月16日，他可以在5月1号至31号之间领取。（前后15天）",
		birthdayNotice1: "请确保您已在",
		birthdayNotice2: "页面填写您的生日。",
		changingBirthdayTo: "将生日更改为",
		minAmount: "最低金额: {min}",
		brandNamePlus: `${brandName}+`,
		EarnDaily: "日收益 > {earn}% *",
		BonusApplied: "已激活奖金",
		ReferEarn: "推荐并赚取",
		GoPlusBalance: `${brandName}+余额`,
		LockBalance: "锁定余额",
		AddPaymentInfo: "添加付款信息",
		NoApplyBonus: "无优惠",
		ApplyBonus: "激活优惠",
		TargetTurnover: "目标流水额",
		GoApply: "立即激活 >>>",
		personalInformation: "个人信息",
		showBalance: "查看余额",
		maxPayoutWarning:
			"请注意，您之前所激活的优惠最高奖金限额为{currency}{amount}。这次转账将包括此游戏的全部金额，但任何超过{currency}{amount}的金额将被扣除。",
		bonusComplete: "奖金完成",
		youHaveCompletedYourBonus: "恭喜！您已完成奖金领取，现在可以将奖金转回到主钱包以继续。",
	},
	checkIn: {
		DailyCheckIn: "每日签到",
		CheckInToday: "今天签到即可获得",
		CheckInSlogan: "打卡越多，奖励越丰富!",
		RecheckInLimit: "可补签日数",
		Day: "第{day}天",
		minigames: "小游戏券x{amount}",
		adjustment: "免费现金{currency}{amount}",
		addonbonus: "附加奖金{amount}%",
		bonus: "免费奖金{currency}{amount} 流水额x{rollover}",
		empty: " - ",
		chips: "泥码x{amount}",
		backdateCheckIn: "补签",
		recheckIn: "补签",
		checkIn: "签到",
		AddOnBonusTitle: "附加奖金",
		MissedDays: "漏签",
		days: "天",
		Credit_1: "每日存款目标",
		Credit_2: "每日下注目标",
		bonusAppliedForAddonBonus: "附加奖金的优惠",
		checkInNotice: [
			{
				notice: `此活动仅适用于 ${brandName} 会员。`,
			},
			{
				notice: "免费现金将在您成功签到后，立即存入您的主钱包，且流水要求为 x1。",
			},
			{
				notice: "如签到奖品是奖金的话，金额将根据具体的流水要求记入您的锁定钱包。",
			},
			{
				notice: "任何导致无效、平局、取消或双方结果相同的投注将不会计入有效投注额。",
			},
			{
				notice: `${brandName} 是本次活动的唯一仲裁者，其决定为最终决定。`,
			},
		],
		errorMsg: {
			E1001: "补签次数已用尽",
			E1002: "无法使用补签",
			E1003: "今日补签已完成",
			E1004: "任务目标未达成",
			E1005: "流水额存在",
			E1006: "请联系客服",
			E1007: "今日补签已完成",
			E1008: "今日补签已完成",
			E1009: "请联系客服",
			E1010: "请联系客服",
			E1011: "请联系客服",
			E1012: "请联系客服",
			E9999: "请联系客服",
		},
	},
	inbox: {
		deleteInbox: "删除信息",
	},
	bonusTypes: {
		Main: "全部",
		SLOTS: "老虎机优惠",
		"LIVE CASINO": "线上赌场优惠",
		FISHING: "捕鱼优惠",
		SPORTS: "体育博彩优惠",
		COCKFIGHT: "斗鸡优惠",
		LOTTERY: "4D优惠",
		"FAST GAME": "快速游戏优惠",
		"NEW MEMBER": "新会员",
		REFERRAL: "推荐优惠",
		WELCOME: "欢迎优惠",
		RESCUE: "救援优惠",
		BIRTHDAY: "生日优惠",
		LOCK: "指定游戏商",
	},
	category: {
		none: "-",
		all: "全部",
		sports: "体育博彩",
		casino: "线上赌场",
		slots: "老虎机",
		livecasino: "真人娱乐",
		fishing: "捕鱼",
		special: "特别",
		autoDeposit: "自动存款",
		manualDeposit: "手动存款",
		eWallet: "电子钱包",
		deposit: "存款",
		withdrawal: "提款",
		transfer: "转账",
		betHistory: "投注",
		bonus: "奖金",
		adjustment: "调整",
		loginPassword: "账户密码",
		gamePassword: "游戏密码",
		lottery: "4D",
		cockfight: "斗鸡",
		minigames: "小游戏",
		promotion: "优惠",
		payment: "付款",
		system: "系统",
		notification: "公告",
		fastgame: "快速游戏",
		interest: `${brandName}+`,
		aboutus: "关于我们",
		terms: "条款与协议",
		privacypolicy: "隐私政策",
		responsiblegaming: "玩家责任",
		ANDROID: "安卓",
		IOS: "苹果",
		download: "下载",
		lock: "指定游戏商",
	},
	footer: {
		gamingLicense: "游戏许可证",
		followUs: "关注我们",
		paymentMethod: "付款方式",
		poweredBy: "赞助商",
		certification: "认证",
		security: "安全",
		responsibleGaming: "玩家责任",
		copyright: `保留所有版权 © ${year} ${brandName} {'|'} 18+`,
		gameLicensesDetails: `${brandName} 提供多种高质量的游戏给到访的玩家。我们的客户服务团队每天24小时全天候待命，随时为您效劳。所有的个人资料都会得到我方严密的处理和保存。`,
	},
	route: {
		home: "大厅",
		download: "下载",
		login: "登录",
		register: "注册",
		forgotpassword: "忘记密码",
		slots: "老虎机",
		casino: "线上赌场",
		fishing: "钓鱼",
		sports: "体育博彩",
		promotion: "优惠",
		aboutus: "关于我们",
		contact: "联系我们",
		terms: "条款与协议",
		privacypolicy: "隐私政策",
		responsiblegaming: "玩家责任",
		vip: "贵宾",
		language: "语言",
		inbox: "收件箱",
		interchange: "切换",
		profile: "个人资料",
		bankinfo: "银行信息",
		referral: "推荐",
		bonus: "奖金",
		deposit: "存款",
		transfer: "转账",
		withdrawal: "提款",
		statement: "帐单",
		changepassword: "更改密码",
		quicktransfer: "快速转账",
		logout: "登出",
		qrcode: "二维码",
		scan: "扫描",
		myaccount: "设置",
		setting: "个人资料",
		checkin: "签到",
		notfound: "页面不存在",
		livechat: "客服",
		theme: "主题",
		interest: `${brandName}+`,
		cashin: "转入",
		cashout: "转出",
		fastgame: "快速游戏",
		notification: "通知",
		search: "搜索",
	},
	language: {
		en: "English",
		ms: "Malay",
		zh: "中文",
	},
	validation: {
		required: "此项为必填内容",
		minAmount: "请输入最小金额 {minAmount}",
		maxAmount: "请输入最大金额 {maxAmount}",
		minLength: "请至少输入 {minLength} 个字符",
		maxLength: "超出字符限制：{maxLength}",
		numeric: "请只输入数字",
		fullNameInvalid: "名字格式不符合 (只允许字符，空格和'-'，不允许连续空格，名字不能以空格开头或结尾)",
		passwordNotMatch: "密码不匹配",
		pleaseAgree: "请表明您已阅读并同意条款和条件以及隐私政策",
		cannotTransferToSameWallet: "无法转账到同一个钱包",
		newPasswordMustBeDifferent: "新密码必须与旧密码不同",
	},
	placeholder: {
		characters: "{minLength} 至 {maxLength} 个字符",
	},
	toast: {
		fetchError: "获取数据失败，请联系客服。{error}",
		autoLogout: "5秒后自动登出",
		sessionExpired: "会话超时。请重新登录。",
		logoutSuccess: "登出成功",
		launchFailed: "启动游戏失败，请联系客服。{error}",
		"turnover exist": "流水额存在，余额将不会被转账",
		"winover exist": "总赢额存在，余额将不会被转账",
		transferSuccess: "转账成功",
		transferFailed: "转账失败。{error}",
		tacSuccess: "TAC请求成功。请检查您的手机",
		tacFailed: "TAC请求失败。请稍后再试",
		tacVerificationFailed: "TAC验证失败",
		submitSuccess: "提交成功",
		submitFailed: "提交失败。请稍后再试。{error}",
		loginFailed: "用户名/密码错误",
		loginSuccess: "登录成功",
		registerSuccess: "注册成功",
		resetPasswordSuccess: "提交成功。请检查您的手机以获取重置密码链接",
		autoTransferEnabled: "您已启用自动传输。请允许系统最多1分钟更新您的偏好。谢谢。",
		autoTransferDisabled: "您已停用自动传输。请允许系统最多 1 分钟更新您的偏好。谢谢。",
		changePasswordSuccess: "密码修改成功。",
		changePasswordFailed: "密码修改失败。 {error}",
		checkInSuccess: "签到成功。",
		checkInFailed: "签到失败。 {error}",
		BookmarkSuccess: "添加书签成功",
		UnBookmarkSuccess: "移除书签成功",
		MarkAsReadSuccess: "已标记为已读",
		MarkAsUnreadSuccess: "已标记为未读",
		RemoveSuccess: "已删除信息",
		BookmarkFailed: "错误：无法添加书签",
		UnBookmarkFailed: "错误：无法移除书签",
		MarkAsReadFailed: "错误：无法标记为已读",
		MarkAsUnreadFailed: "错误：无法标记为未读",
		RemoveFailed: "错误：无法删除信息",
		ticketSuccess: "交易编号 : {id} 已被批准",
		ticketReject: "交易编号 : {id} 已被拒绝。请联系我们的客服",
		claimSuccessful: "领取成功.",
		claimUnsuccessful: "领取失败. {error}",
		dailyMissionIncomplete_1: "任务目标未达成, 请完成您的每日存款目标",
		dailyMissionIncomplete_2: "任务目标未达成, 请完成您的每日下注目标",
		newInboxReceived: "您有新的信息!",
		checkInNotStartYet: "抱歉，签到活动尚未开始。请稍后再查看。",
		depositFailed: "存款失败。请稍后再试。 {error}",
		AllowOneDeposit: "前一个存款单仍在处理中。",
		AllowOneActiveWithdrawal: "前一个提款单仍在处理中。",
		miniGameisOpened: "小游戏已在另一个标签页中运行",
		newVersion: "新版本已发布，点击这里更新",
		popUpBlocked: "您已启用了弹出窗口拦截器，无法启动游戏",
		urlNotFound: "无效网址",
		InsufficientBalanceWithdrawal: "余额不足无法提款",
		GameMaintance: "游戏正在维护中",
		launchGameError: "启动游戏错误。 获取 URL 失败",
		referral: {
			errorMsg: {
				"521": "未找到推荐 ID。",
				"522": "未找到推荐奖金。",
				"523": "无法领取推荐奖金。 未满足要求。",
				"524": "无法领取推荐奖金。 流水额存在。",
				"525": "无法领取推荐奖金。 总赢额存在。",
				"526": "没有推荐奖金可以领取。",
				"529": "领取奖金失败。 请联系客服。",
			},
		},
		notificationGranted: "您已经开启通知功能的权限。",
		notificationDenied: "通知功能的权限已被关闭。请在您的设备设置中启用。",
		notificationAllow: "您的通知功能已启用",
		notificationNotAllow: "您的通知功能已被停用。",
		notificationNotSupport: "您的浏览器不支持通知功能",
		cameraDenied: "相机权限已被拒绝。请在您的设备设置中启用它。",
		cameraNotSupport: "您的浏览器或设备不支持此功能",
		accountAccessed: "您的账号已在另一台设备上登录。请重新登录。",
		toastOffline: "您似乎处于离线状态。请检查您的网络连接。",
		vendorRestricted: "您所领取的优惠不支持此游戏，请重新选择优惠条款里指定的游戏。",
		claimRegisterBonusUnsuccessful: "无法领取注册奖金。 未满足要求。",
		claimReferralBonusUnsuccessful: "无法领取推荐奖金。 未满足要求。",
		gameUnderMaintenance: "游戏正在维护。",
		bonusOngoing: "您目前已有激活的优惠。请点击此处查看。",
	},
	notification: {
		granted: "已启用",
		denied: "已停用",
		default: "立即启用",
		notSupport: "不支持",
	},
	about: {
		about1: `${brandName} 真人赌场提供许多最受欢迎的赌场游戏，您可以在世界上一些最著名的赌场找到这些游戏。 享受安全可靠的在线赌场体验，在这里您可以玩在线老虎机、扑克、百家乐、轮盘赌、二十一点以及更多在线赌场游戏！`,
		title2: `欢迎来到顶级和值得信赖的马来西亚在线赌场：${brandName}`,
		about2: `${brandName}，马来西亚最佳在线赌场，提供无与伦比的赌场游戏选择，包括多种老虎机选项。每位玩家都可以加入我们，体验最具回报的赌场游戏，许多游戏还提供老虎机免费信用促销，让您轻松入门。 \n\n 尽情享受最佳赌场游戏，所有游戏均配有高质量音效和引人入胜的主题。在我们的在线赌场游戏中试试运气，包括流行的老虎机电子钱包选项，方便安全地进行交易。立即加入我们，赢取令人兴奋的奖励。畅玩您最喜欢的老虎机游戏，如918kiss、mega888、pragmatic、jili等。 \n\n 今天感觉幸运吗？别再犹豫了——立即开始您的游戏冒险，选择最佳的马来西亚在线博彩平台。${brandName}赌场随时等待您在任何地方畅玩，无论您是在桌面还是移动设备上，都能提供无缝体验。 \n\n 体验老虎机电子钱包交易的便利，快速存款和取款。我们的老虎机免费信用促销让您在不冒自己资金风险的情况下，有更多机会赢得大奖。作为领先的在线赌场马来西亚提供商，${brandName}致力于提供卓越的游戏体验，结合多样性、安全性和丰厚的奖金。 \n\n 别错过精彩的时刻——今天就加入${brandName}，发现我们为何是马来西亚玩家寻找最佳在线赌场娱乐的首选！`,
		aboutUs: `介绍 ${brandName}，这是一个在${year}年${month}月于马来西亚推出的顶级在线赌博平台！${brandName} 为每一位游戏爱好者量身定制，提供广泛的刺激性赌场游戏，包括 GreatBlue, Safari Heat, Dolphin Reef, Iceland, 泰拳, 二十一点, 轮盘, 和百家乐。无论你喜欢经典的桌面游戏还是令人兴奋的老虎机，我们都能满足你的需求。
					\n通过 ${brandName}，你可以随时随地在你的 Android/iOS 手机、平板电脑或桌面设备 上享受你最喜欢的游戏。我们的平台拥有 用户友好且简洁的界面，确保即使是新手也能轻松导航并立即开始游戏。玩家必须年满18岁才能加入，符合法律规定。
					\n体验在家中舒适地投注时，如同置身于 云顶高原 Sky Casino 的快感。我们的 高度安全平台 优先保护您的 隐私和数据安全，提供无与伦比的安全性并防止任何数据泄露的风险。安全是我们的首要任务，让您可以专注于享受游戏。
					\n${brandName} 还具有其他平台没有的独特功能，如我们的 VIP 系统。充值越多，享受的奖励就越多，提供您独家优惠和奖金。
					\n此外，您每天可以通过玩 迷你游戏 获得 免费奖励！${brandName} 提供多种游戏类别，包括 老虎机、真人赌场、捕鱼、体育、电子竞技和迷你游戏，确保每位玩家都能找到自己喜欢的游戏。
					\n今天就访问 ${brandName} 网站，探索充满激动和奖励的世界吧！`,
	},
	tutorial: {
		step1Title: "加入",
		step1Desc: "快速&免费创建账户。",
		step2Title: "存款",
		step2Desc: "选择您的存款方式。",
		step3Title: "转账",
		step3Desc: "转账到您的游戏钱包。",
		step4Title: "提款",
		step4Desc: "方便快捷提取赢利。",
	},
	vip: {
		enhanceYourGaming: "提升您的游戏体验:\n步入高级赌场贵宾厅。",
		experiencePrivilege: `开启 ${brandName} 一系列的贵宾特权。`,
		vipIntro: `通过全新的VIP计划，忠实会员在${brandName}游戏中能够获得更多好处。这不仅仅是奖励，我们还为VIP会员提供专属促销和回馈最佳的奖励玩法。\n\n除了独家优惠外，VIP会员是我们的首要关注对象。无论是存款还是提款，抑或任何游戏问题，所有VIP请求都是我们优先处理的。`,
		vipMemberLevel: "VIP会员等级",
		firstDeposit: "首次存款",
		minDeposit: "最低存款额",
		byInvitation: "通过邀请",
		vipPrivileges: "贵宾特权",
		vipBenefits: "贵宾福利",
		upgradeBonus: "升级奖金",
		birthdayBonus: "生日奖金",
		dailyRescueBonus: "每日救援金",
		weeklyRescueBonus: "每周救援金",
		accountManager: "专属 VIP 助理",
		specialRebate: "特别回扣",
		slotsRebate: "老虎机返水",
		liveCasinoRebate: "在线赌场返水",
		sportsRebate: "体育返水",
		fishingRebate: "钓鱼返水",
		upgradeRequirement: "等级升级要求",
		minDepositWithinAMonth: "一个月内存款数额",
		minDepositWithinAYear: "一年内存款数额",
		retentionRequirement: "等级保留要求",
		lifetimeDeposit: "累积存款",
		membershipRenewal: "等级保留期",
		lifetime: "永久",
		once: "一次",
		always: "时常",
		daily: "每天",
		weekly: "每周",
		monthly: "每个月",
		quarterly: "每季度",
		"half yearly": "每半年",
		yearly: "每年",
		vipExclusive: "贵宾专属",
		compulsory: "VIP升级需实名认证",
		vipStatus: "当前VIP状态",
		increaseBalanceText: "立即存款以解锁新的VIP等级！",
		amountToReach: "所需金额以升级至",
		currentProgress: "当前进展",
		welcomeBack: "欢迎回来！我们非常重视您的忠诚与热情。",
		totalDeposit: "总存款",
		tnc: [
			{
				term: "首次升级时，将会要求会员提供所有必要的文件以进行账户验证。当验证核实完毕后，会员的VIP级别状态将在3个工作日内更新到系统中。\n验证账户步骤如下:\n\ni. 验证帐户详细信息（全名，电话号码和电子邮件地址）\nii. 验证帐户活动（投注方式，存款和提款记录）\n\n重要公告: 在为期3天的验证期内，如有发现任何可疑或异常活动，管理层将保留或拒绝该会员的升级资格权利。",
			},
			{
				term: "一旦升级后即可享有所有的VIP特权，并且根据当时达到的最高级别为准，有效期为3个月。\n重要公告: 为了保持您的会员级别状态，会员必须在其中一个季度内达到相同的需求以延长级别的有效期。",
			},
			{
				term: `所有升级VIP 级别状态的邀请将由 ${brandName} 的VIP部门发出。`,
			},
			{
				term: `若发现有任何可疑或异常活动的VIP 账户，${brandName} 将保留随时可以撤销该会员的VIP特权，降级或终止该会员的VIP级别的权利。恕不另行通知\n重要公告: 每个会员资格可能会根据帐户活动而降级。`,
			},
			{
				term: `所有 ${brandName} 管理层决定视为最终决定。`,
			},
		],
		faq: [
			{
				q: "如何成为 VIP 会员？",
				a: "您只需要至少存款RM10,000 在当季（1个月）既可成为白银会员。",
			},
			{
				q: "有多少个VIP级别?",
				a: "总共有6个级别。有青铜，白银，黄金，铂金，钻石和皇家。",
			},
			{
				q: "如何晋升到下一个级别？",
				a: "您只需要达到每一个指定级别的存款既可。例如，如果您目前是青铜会员，您需要存入至少 RM10,000 才能成为白银会员，而从白银会员开始，您需要在30天以内再存入至少 RM40,000 才能成为黄金会员。",
			},
			{
				q: "在有效期结束之前，我要如何维持我的会员级别?",
				a: "对于维持1年的会员级别，您将需要在有效期结束之前的最后一个季节达到指定的存款金额。 例如：如果您的黄金会员身份在一月至十二月期间有效，您则需要在同一年的最后季度（十月）达到RM25,000 的存款，以维持下一年度的黄金会员级别。 青铜和白银的会员级别则是终身有效。",
			},
			{
				q: " 作为VIP 会员，是否需要支付月费或年费？",
				a: "不需要，这个项目旨在为所有忠诚会员并积极支持我们的玩家给予回馈和更好的游戏体验。",
			},
		],
		nextTierProgress: "下一级进展",
		choosePlan: "选择您的VIP计划",
		upgradeNow: "立即升级",
		upgrading: "恭喜，您即将升级",
		currentTier: "当前等级",
	},
	error: {
		InvalidWithdrawalAmount: "提款金额无效",
		ReachDailyLimit: "超出每日限额",
		TurnoverExceeded: "超出流水额",
		WithdrawalFailed: "提款失败，请联系客服",
		MemberNameExist: "会员名称已存在，请联系客服",
	},
	addMessages: {
		ios1: "1. 在 iOS 浏览器中打开分享菜单",
		ios2: '2. 点击"添加到主屏幕"按钮',
		android: `1. 打开浏览器设置
	2. 点击"添加到主屏幕"`,
		windows: {
			chrome: "点击导航栏右侧的 (+) 按钮。",
			firefox: `1. 调整浏览器大小以便看到桌面
	2. 将导航栏左侧的 (i) 按钮拖放到桌面`,
		},
		macos: {
			chrome: "点击导航栏右侧的 (+) 按钮。",
			safari: `1. 调整浏览器大小以便看到桌面
	2. 将通知栏左侧的地球图标拖放到桌面`,
			firefox: `1. 调整浏览器大小以便看到桌面
	2. 将导航栏左侧的 (i) 按钮拖放到桌面`,
		},
		others: "看起来您的浏览器不支持原生添加到主屏幕功能。请随时更新/更换浏览器。",
	},
	terms,
	privacyPolicy,
	responsible,
	goPlusTerms,

	// ================= STATEMENT =================

	statement: {
		id: "ID",
		method: "方式",
		amount: "共计",
		date: "日期",
		status: "状态",
		receipt: "收据",
		transactionType: "类型",
		vendorFrom: "从",
		vendorTo: "至",
		betId: "投注ID",
		gameName: "游戏",
		turnover: "投注金额",
		winOrLoss: "盈利/亏损",
		bonusName: "优惠",
		bonusAmount: "金额",
		group: "分类",
		targetAmount: "目标金额",
		New: "新增",
		Success: "成功",
		Processing: "处理中",
		Rejected: "拒绝",
		RUNNING: "进行中",
		COMPLETED: "已完成",
		CANCELLED: "已取消",
		SLOTS: "老虎机优惠",
		"LIVE CASINO": "线上赌场优惠",
		FISHING: "捕鱼优惠",
		SPORTS: "体育博彩优惠",
		COCKFIGHT: "斗鸡优惠",
		LOTTERY: "4D优惠",
		"FAST GAME": "快速游戏优惠",
		"NEW MEMBER": "新会员",
		REFERRAL: "推荐优惠",
		cashIn: "转入",
		cashOut: "转出（本金）",
		cashOutLock: "转出（利息）",
		MAIN: "主钱包",
		Interest: `${brandName}+`,
		LOCK: "锁定钱包",

		ReferralAdj: "推荐奖金",
		RegBonus: "注册奖金",
		DepositAdj: "存款调整",
		WithdrawalAdj: "提款调整",
		RebateAdj: "返水",
		MiniGamesAdj: "小游戏奖金",
		RescueAdj: "救援奖金",
		BirthdayAdj: "生日奖金",
		RankUpAdj: "升级奖金",
		CheckInAdj: "活动免费奖金",
		ChipDepositAdj: "筹码存款调整",
		ChipWithdrawalAdj: "筹码提取调整",
		"BO Deposit": "后台存款",
		AutoDeposit: "自动存款",
		DepositReferral: "推荐存款",
		ManualDeposit: "手动存款",
		Withdrawal: "提款",
		DAILY: "每日",
		WEEKLY: "每周",
	},
};
