import { LiveDataStateInterface } from "./state";
import { MutationTree } from "vuex";
import { ProgressiveJackpotResponse, LiveTransactionResponse } from "@/types/LiveData/Response/liveDataResponse";

export enum livaDataMutations {
	SET_PROGRESSIVE_JACKPOT = "SET_PROGRESSIVE_JACKPOT",
	SET_PROGRESSIVE_JACKPOT_VALUE = "SET_PROGRESSIVE_JACKPOT_VALUE",
	SET_LIVE_DEPOSIT = "SET_LIVE_DEPOSIT",
	SET_LIVE_WITHDRAWAL = "SET_LIVE_WITHDRAWAL",
}

export const mutations: MutationTree<LiveDataStateInterface> = {
	[livaDataMutations.SET_PROGRESSIVE_JACKPOT](state, payload: ProgressiveJackpotResponse) {
		state.progressiveJackpot = payload;
	},
	[livaDataMutations.SET_PROGRESSIVE_JACKPOT_VALUE](state, payload: number) {
		state.progressiveJackpot = {
			...state.progressiveJackpot,
			jackpot: payload,
		};
	},
	[livaDataMutations.SET_LIVE_DEPOSIT](state, payload: LiveTransactionResponse[]) {
		state.liveDepositList = payload;
	},
	[livaDataMutations.SET_LIVE_WITHDRAWAL](state, payload: LiveTransactionResponse[]) {
		state.liveWithdrawalList = payload;
	},
};
